import axios from 'axios'
import { getServerHost } from './index'
import store from '../store'
import Vue from 'vue'
import * as utils from '@/utils/utils'
import { pinmeapi } from '@/api/pinme'

const serverHost = getServerHost()
const baseUrl = `${window.location.protocol}//${serverHost}:${window.location.port}/api/`
const devices = baseUrl + 'devices'
const route = baseUrl + 'reports/route'
const events = baseUrl + 'reports/events'
const positions = baseUrl + 'positions'
const trips = baseUrl + 'reports/trips'
const stops = baseUrl + 'reports/stops'
const summary = baseUrl + 'reports/summary'
const geoFences = baseUrl + 'geofences'
const alerts = baseUrl + 'notifications'
const permissions = baseUrl + 'permissions'
const groups = baseUrl + 'groups'
const users = baseUrl + 'users'
const server = baseUrl + 'server'
const drivers = baseUrl + 'drivers'
const session = baseUrl + 'session'
const maintenances = baseUrl + 'maintenance'
const commands = baseUrl + 'commands'
const s3_report_lambda_url = 'https://' + serverHost + '/api_reports'
const api_helper_lambda_url = '/api_helper'
const command_send = baseUrl + 'commands/send'

export function login(data) {
  const body = 'email=' + encodeURIComponent(data.username) + '&password=' + encodeURIComponent(data.password)
  console.log('login', session)
  return axios({
    withCredentials: true, // send cookies when cross-domain requests
    url: session,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: body
  })
}
export function logout() {
  return axios({
    withCredentials: true, // send cookies when cross-domain requests
    timeout: 5000,
    url: session,
    method: 'delete',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}
export function getSession() {
  return axios.get(session, { withCredentials: true })
}

/**
 * @deprecated
 */
function invokeApi(url, onFulfill, onError) {
  return new Promise((resolve, reject) => {
    axios.get(url, { withCredentials: true }) // send cookies when cross-domain requests)
      .then(response => {
        store.dispatch('connectionOk', { state: true }).then(() => {
          if (onFulfill) {
            onFulfill(response.data)
          }
          resolve(response.data)
        })
      })
      .catch(reason => {
        store.dispatch('connectionOk', { state: false }).then(() => {
          if (onError) {
            onError(reason)
          }
          if (reject) {
            reject(reason)
          }
        })
      })
  })
}

function invokeDeleteApi(url, id, onFulfill) {
  return new Promise((resolve, reject) => {
    axios.delete(url + '/' + id, { withCredentials: true })
      .then(onFulfill(id))
      .catch(error => {
        reject(error)
      })
  })
}

function invokeApiMultiple(urls, onFulfill) {
  return new Promise((resolve, reject) => {
    axios.all(urls)
      .then(axios.spread((...responses) => {
        Vue.$log.debug(responses)
        onFulfill(responses.map(r => r.data))
      }))
      .catch(e => {
        Vue.$log.error((e.response && e.response.data) || e)
        reject(e)
      })
  })
}

function invokeApiAll(urls) {
  return axios.all(urls)
}

function get(url) {
  return axios.get(url, { withCredentials: true })
}

export const traccar = {
  sendCommand: (id, deviceId) => {
    return axios.post(command_send, { id, deviceId }, { withCredentials: true })
  },
  api_helper: function(options, ok, nok) {
    axios.post(
      api_helper_lambda_url,
      options,
      {
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true,
        timeout: 30000
      })
      .then(response => ok(response))
      .catch(reason => nok(reason))
  },
  trigger_report(body, report_id, ok, nok) {
    axios.post(s3_report_lambda_url,
      body,
      {
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true,
        timeout: 29000 // Maximum timeout for the Lambda API Gateway
      }
    )
      .then(() => ok(report_id))
      .catch(reason => nok(report_id, reason))
  },
  get_report: function(body, report_id, ok, nok) {
    axios.post(s3_report_lambda_url,
      body,
      {
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true,
        timeout: 29000 // Maximum timeout for the Lambda API Gateway
      }
    )
      .then(response => ok(response.data))
      .catch(reason => nok(report_id, reason))
  },
  report_events(from, to, deviceIds, types) {
    from = from.toISOString()
    to = to.toISOString()
    types = types.map(n => 'type=' + encodeURI(n)).join('&')

    if (deviceIds.length > 100) {
      const splitedDeviceIds = utils.chunkArray(deviceIds, 100)
      const allUrls = splitedDeviceIds.map(pIds => get(`${events}?${pIds.map(d => 'deviceId=' + d).join('&')}&${types}&from=${from}&to=${to}`, { withCredentials: true }))
      return invokeApiAll(allUrls)
    } else {
      return invokeApiAll([get(`${events}?${deviceIds.map(d => 'deviceId=' + d).join('&')}&${types}&from=${from}&to=${to}`, { withCredentials: true })])
    }
  },
  devices: function(onFulfill, onError) {
    invokeApi(devices, onFulfill, onError)
  },
  devicesByUser: function(userId) {
    return get(devices + '?userId=' + userId, { withCredentials: true })
  },
  async updateDevice(deviceId, device, retries = 1) {
    delete device.poi
    delete device.driver
    delete device.position
    delete device.geofences
    delete device.lastStop
    delete device.currentTrip
    delete device.attributes.commandPending
    delete device.route
    delete device.lastUpdate
    delete device.routePending

    Vue.$log.debug('updateDevice', device.name)
    try {
      return await axios.put(devices + '/' + deviceId, device, { withCredentials: true })
    } catch (e) {
      if (--retries) {
        await new Promise(res => setTimeout(res, 1000))
        await this.updateDevice(deviceId, device, retries)
      } else {
        throw e
      }
    }
  },
  updateDeviceAccumulators(deviceId, accumulators) {
    return pinmeapi.updateDeviceAccumulators(deviceId, accumulators)
  },
  updateUser(userId, user) {
    return axios.put(users + '/' + userId, user, { withCredentials: true })
  },
  route: function(deviceId, from, to, onFulfill) {
    from = Vue.moment(from).startOf('day').toDate()
    to = Vue.moment(to).endOf('day').toDate()
    axios.get(route + '?nocache=' + new Date().toISOString() + '&deviceId=' + deviceId + '&from=' + from.toISOString() + '&to=' + to.toISOString(), { withCredentials: true })
      .then(response => onFulfill(response.data))
      .catch(reason => {
        Vue.$log.error(reason)
      })
  },
  allInOne(deviceId, from, to, types) {
    const queryTypes = types ? types.map(t => '&type=' + t).join('') : '&type=route&type=trips&type=stops&type=summary'
    return axios.get(
      `${baseUrl}reports/allinone?deviceId=${deviceId}&from=${from.toISOString()}&to=${to.toISOString()}${queryTypes}`,
      { withCredentials: true }
    ).then(r => r.data)
  },
  summary: (deviceId, from, to) => {
    from = Vue.moment(from).startOf('day').toDate()
    to = Vue.moment(to).endOf('day').toDate()
    return axios.get(summary + '?nocache=' + new Date().toISOString() + '&deviceId=' + deviceId + '&from=' + from.toISOString() + '&to=' + to.toISOString(), { withCredentials: true })
  },
  positions(positionIds) {
    if (positionIds) {
      if (positionIds.length > 20) {
        const splitedPositionsIds = utils.chunkArray(positionIds, 20)
        const allUrls = splitedPositionsIds.map(pIds => get(positions + '?' + pIds.map(p => 'id=' + p).join('&')))
        return invokeApiAll(allUrls)
      } else {
        const params = positionIds.map(p => 'id=' + p).join('&')
        return invokeApiAll([get(positions + '?' + params)])
      }
    } else {
      return get(positions)
    }
  },
  position(positionId, deviceId) {
    return get(positions + '?id=' + positionId + (deviceId ? '&deviceId=' + deviceId : ''))
  },
  trips: function(devices, from, to) {
    to = Vue.moment(to).endOf('day').toDate()
    return axios.get(trips + '?from=' + from.toISOString() + devices.map(d => '&deviceId=' + d).join('') + '&to=' + to.toISOString(),
      { withCredentials: true })
  },
  stops: function(devices, from, to) {
    to = Vue.moment(to).endOf('day').toDate()
    return axios.get(stops + '?from=' + from.toISOString() + devices.map(d => '&deviceId=' + d).join('') + '&to=' + to.toISOString(),
      { withCredentials: true })
  },
  stopReceiving: function() {
  },
  newGeofence(name, description, area, attributes) {
    const body = {
      name: name,
      description: description,
      area: area,
      attributes
    }
    if (area.startsWith('LINESTRING')) {
      body.attributes = {
        polylineDistance: 100
      }
    }
    Vue.$log.debug(area)
    return axios.post(geoFences, body, { withCredentials: true })
  },
  editGeofence: function(geofenceId, geofence) {
    const geofenceToUpdate = { ...geofence }
    delete geofenceToUpdate.groupName
    delete geofenceToUpdate.visible
    return axios.put(geoFences + '/' + geofenceId, geofenceToUpdate, { withCredentials: true })
  },
  deleteGeofence: function(geofenceId, onFulfill) {
    pinmeapi.deleteGeofence(geofenceId).then(() => onFulfill(geofenceId))
  },
  geofences: function(onFulfill, onError) {
    invokeApi(geoFences, onFulfill, onError)
  },
  geofencesByGroup: function(groups, onFulfill) {
    Vue.$log.debug('geofencesByGroup')
    const groupsUrl = groups.map(groupId => axios.get(geoFences + '?groupId=' + groupId, { withCredentials: true }))
    return invokeApiMultiple(groupsUrl, onFulfill)
  },
  geofencesByUser: function(userId) {
    Vue.$log.debug('geofencesByUser')
    return get(geoFences + '?userId=' + userId, { withCredentials: true })
  },
  usersByUser: function(userId) {
    return get(users + '?userId=' + userId, { withCredentials: true })
  },
  geofencesByDevice(deviceId) {
    return axios.get(geoFences + '?deviceId=' + deviceId, { withCredentials: true }).then(r => r.data)
  },
  alertsByDevice: function(deviceId) {
    return get(alerts + '?deviceId=' + deviceId, { withCredentials: true })
  },
  alertsByUser: function(userId) {
    Vue.$log.debug('alertsByUser')
    return get(alerts + '?userId=' + userId, { withCredentials: true })
  },
  alerts() {
    return get(alerts, { withCredentials: true })
  },
  newAlert(alert) {
    return axios.post(alerts, alert, { withCredentials: true }).then(r => r.data)
  },
  updateAlert(alertId, alert) {
    return axios.put(alerts + '/' + alertId, alert, { withCredentials: true })
      .then(response => response.data)
  },
  deleteAlert(id) {
    return axios.delete(alerts + '/' + id, { withCredentials: true })
  },
  addPermission: function(permission, onFulfill) {
    Vue.$log.debug(permission)
    axios.post(permissions, permission, { withCredentials: true })
      .then(response => {
        if (onFulfill) {
          onFulfill(response.data)
        }
      })
      .catch(reason => {
        Vue.$log.error(reason)
      })
  },
  async addAllPermissions(permissionsToAdd) {
    if (permissionsToAdd.length > 0) {
      store.commit('transient/SET_PERCENTAGE', 0)
      const chunk = 5000
      for (let i = 0; i < permissionsToAdd.length; i += chunk) {
        await axios.post(permissions + '/bulk', permissionsToAdd.slice(i, i + chunk), { withCredentials: true })
          .catch(e => Vue.$log.error(e))
        store.commit('transient/SET_PERCENTAGE',
          Math.round(Math.min(i + chunk, permissionsToAdd.length) / permissionsToAdd.length * 100))
      }
      store.commit('transient/SET_PERCENTAGE', 0)
    }
  },
  deletePermission(permission) {
    Vue.$log.debug(permission)
    return axios.delete(permissions, { data: permission, withCredentials: true })
  },
  deleteAllPermissions(permissionsToDelete) {
    Vue.$log.debug(permissionsToDelete)
    if (permissionsToDelete.length > 0) {
      return axios.delete(permissions + '/bulk', { data: permissionsToDelete, withCredentials: true })
    }
  },
  groups: function(userId, onFulfill, onError) {
    invokeApi(groups + '?userId=' + userId, onFulfill, onError)
  },
  editGroup(groupId, group) {
    Vue.$log.debug(group)
    return axios.put(groups + '/' + groupId, group, { withCredentials: true })
  },
  deleteGroup: function(groupId) {
    return axios.delete(groups + '/' + groupId, { withCredentials: true })
  },
  newGroup(group) {
    Vue.$log.debug(group)
    return axios.post(groups, group, { withCredentials: true })
  },
  drivers: function(userId, onFulfill, onError) {
    invokeApi(drivers + '?userId=' + userId, onFulfill, onError)
  },
  driversByGroup: function(groups, onFulfill) {
    Vue.$log.debug('driversByGroup')
    const groupsUrl = groups.map(groupId => axios.get(drivers + '?groupId=' + groupId, { withCredentials: true }))
    return invokeApiMultiple(groupsUrl, onFulfill)
  },
  driversByUser: function(userId) {
    Vue.$log.debug('driversByUser')
    return get(drivers + '?userId=' + userId, { withCredentials: true })
  },
  groupsByUser: function(userId) {
    Vue.$log.debug('groupsByUser')
    return get(groups + '?userId=' + userId, { withCredentials: true })
  },
  groupsByUsers: function(users, onFulfill) {
    Vue.$log.debug('groupsByUser')
    const usersUrl = users.map(userId => axios.get(groups + '?userId=' + userId, { withCredentials: true }))
    Vue.$log.debug('groupsByUser', usersUrl)
    return invokeApiMultiple(usersUrl, onFulfill)
  },
  addDriver: function(driver) {
    return axios.post(drivers, driver, { withCredentials: true })
  },
  deleteDriver: function(driverId) {
    return axios.delete(drivers + '/' + driverId, { withCredentials: true })
  },
  updateDriver: function(driverId, driver) {
    Vue.$log.debug(driver)
    delete driver.vehicle
    return axios.put(drivers + '/' + driverId, driver, { withCredentials: true })
  },
  users: function() {
    return get(users, { withCredentials: true })
  },
  addUser: function(user) {
    return axios.post(users, user, { withCredentials: true })
  },
  deleteUser: function(userId, onFulfill) {
    invokeDeleteApi(users, userId, onFulfill)
  },
  maintenances: function() {
    return get(maintenances, { withCredentials: true })
  },
  addMaintenance: function(maintenance) {
    return axios.post(maintenances, maintenance, { withCredentials: true })
  },
  deleteMaintenance: function(maintenanceId) {
    return axios.delete(maintenances + '/' + maintenanceId, { withCredentials: true })
  },
  editMaintenance: function(maintenanceId, maintenance) {
    delete maintenance.devices
    delete maintenance.groups
    delete maintenance.devicesMaintenances
    return axios.put(maintenances + '/' + maintenanceId, maintenance, { withCredentials: true })
  },
  maintenancesByDevice: function(devices) {
    return Promise.all(devices.map(deviceId => axios.get(maintenances + '?deviceId=' + deviceId, { withCredentials: true })
      .then(d => d.data)))
  },
  maintenancesByGroup: function(groups) {
    return Promise.all(groups.map(groupId => axios.get(maintenances + '?groupId=' + groupId, { withCredentials: true })
      .then(d => d.data)))
  },
  commandsByUser: function(userId) {
    Vue.$log.debug('commandsByUser')
    return get(commands + '?userId=' + userId, { withCredentials: true })
  },
  ping: function() {
    return get(server)
  },
  getUser() {
    return get(baseUrl + 'session')
  },
  getSession() {
    return invokeApi(baseUrl + 'session')
  },
  getDevices() {
    return axios.get(devices, { withCredentials: true })
  },
  getOtherData(user) {
    const requestGeofences = axios.get(geoFences, { withCredentials: true })
    const requestGroups = axios.get(groups + '?userId=' + user.id, { withCredentials: true })
    const requestDrivers = axios.get(drivers + '?userId=' + user.id, { withCredentials: true })
    const requestMaintenance = axios.get(maintenances + '?userId=' + user.id, { withCredentials: true })
    const requestCommands = axios.get(commands + '?userId=' + user.id, { withCredentials: true })
    const urls = [requestGeofences, requestGroups, requestDrivers, requestMaintenance, requestCommands]
    if (!user.readonly && user.userLimit !== 0) {
      urls.push(axios.get(users, { withCredentials: true }))
    }
    return axios.all(urls)
  }
}
