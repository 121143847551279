import { traccar, login, logout } from '@/api/traccar-api'
import { serverBus, vm } from '@/main'
import Vue from 'vue'
import { setLanguage } from '@/lang'
import { Auth } from '@aws-amplify/auth'
import api from '@/api/backend'
import backend from '@/api/backend'
import * as Sentry from '@sentry/vue'
import router from '../../router'
import { pinmeapi } from '@/api/pinme'
import { findNearestPOI } from '@/utils/lnglat'
import { validEmail } from '@/utils/validate'
import * as lnglat from '@/utils/lnglat'

const state = {
  user: {
    name: '',
    email: '',
    phone: '',
    attributes: {}
  },
  alerts: [],
  devices: [],
  groups: [],
  geofences: [],
  drivers: [],
  users: [],
  maintenances: [],
  commands: [],
  driverUniqueIds: [],
  userChangePass: null,
  loadNotificationByDevice: false
}

const mutations = {
  SET_LOAD_NOTIFICATIONS_BY_DEVICE(state, value) {
    console.log('SET_LOAD_NOTIFICATIONS_BY_DEVICE', value)
    state.loadingNotificationByDevice = value
  },
  SET_GROUP_DRIVERS(state, { index, drivers }) {
    const group = state.groups[index]
    group.drivers = drivers
    state.groups.splice(index, 1, group)
  },
  SET_USER_CHANGEPASS(state, user) {
    state.userChangePass = user
  },
  SET_EMAIL_AUTH_HASH(state, hash) {
    state.user.attributes.emailAuthHash = hash
  },
  SET_USERID_AUTH_HASH(state, hash) {
    state.user.attributes.userIdAuthHash = hash
  },
  SET_ALERT_SEARCH_PERIOD(state, alertsSearchPeriod) {
    state.user.attributes = { ...state.user.attributes, alertsSearchPeriod }
  },
  SET_GEOFENCES(state, geofences) {
    state.geofences = geofences
  },
  SET_GEOFENCE(state, geofence) {
    const index = state.geofences.indexOf(geofence)
    state.geofences.splice(index, 1, geofence)
  },
  SET_GEOFENCE_VISIBLE(state, { geofence, visible }) {
    const index = state.geofences.indexOf(geofence)
    state.geofences.splice(index, 1, { ...geofence, visible })
  },
  UPDATE_GROUP(state, { oldGroup, newGroup }) {
    const index = state.groups.indexOf(oldGroup)
    state.groups.splice(index, 1, newGroup)
  },
  SET_DEVICES(state, devices) {
    state.devices = devices
  },
  SET_USER(state, token) {
    state.user = token
    state.user.attributes.avatar = getAvatar(token.name)
  },
  SET_MAINTENANCES(state, maintenances) {
    state.maintenances = maintenances
  },
  SET_DRIVERUNIQUEIDS(state, uniqueIds) {
    state.driverUniqueIds = uniqueIds
  },
  CLEAR_USER(state) {
    state.user = {
      name: '',
      id: 0,
      email: '',
      phone: '',
      avatar: '',
      attributes: {
        timezone: ''
      }
    }
  },
  SET_ALERTS(state, alerts) {
    Vue.$log.debug('SET_ALERTS', alerts)
    state.alerts = alerts
  },
  ADD_DRIVER(state, driver) {
    state.drivers.push(driver)
  },
  ADD_UNIQUEID(state, uniqueId) {
    state.driverUniqueIds.push(uniqueId)
  },
  REMOVE_UNIQUEID(state, uniqueId) {
    const index = state.driverUniqueIds.indexOf(uniqueId)
    state.driverUniqueIds.splice(index, 1)
  },
  REMOVE_DRIVER(state, driver) {
    const index = state.drivers.indexOf(driver)
    state.drivers.splice(index, 1)
  },
  ADD_USER(state, user) {
    state.users.push(user)
  },
  REMOVE_USER(state, user) {
    const index = state.users.indexOf(user)
    state.users.splice(index, 1)
  },
  SET_DEVICE(state, device) {
    const index = state.devices.indexOf(device)
    state.devices.splice(index, 1, device)
  },
  UPDATE_LAST_UPDATE(state, device) {
    const index = state.devices.indexOf(device)
    state.devices.splice(index, 1, device)
  },
  ADD_MAINTENANCE(state, maintenance) {
    state.maintenances.push(maintenance)
  },
  REMOVE_MAINTENANCE(state, maintenance) {
    const index = state.maintenances.indexOf(maintenance)
    state.maintenances.splice(index, 1)
  },
  SET_MAINTENANCE(state, maintenance) {
    const index = state.maintenances.indexOf(maintenance)
    state.maintenances.splice(index, 1, maintenance)
  }
}

function getAvatar(name) {
  const nameSplit = name.split(' ')
  return nameSplit[0].charAt(0).toUpperCase() + (nameSplit[1] ? nameSplit[1].charAt(0).toUpperCase() : nameSplit[0].charAt(1).toUpperCase())
}

async function initData(commit, state, dispatch) {
  const user = state.user
  user.attributes.lastLogin = new Date()
  const { data } = await traccar.getDevices()
  commit('SET_DEVICES', data)
  dispatch('transient/setDevicesLoaded', null, { root: true })
  Vue.$log.info('emit devicesLoaded')
  serverBus.$emit('devicesLoaded')
  traccar.getOtherData(user)
    .then(responses => {
      dispatch('setGeofences', responses[0].data).then(async() => {
        state.groups = responses[1].data
        state.groups.sort((a, b) => a.name.localeCompare(b.name))
        state.drivers = responses[2].data
        state.maintenances = responses[3].data
        state.commands = responses[4].data
        if (!user.readonly && user.userLimit !== 0) {
          state.users = responses[5].data.filter(u => u.id !== user.id)
        } else {
          state.users = []
        }
        dispatch('processGroups')
          .then(() => {
            dispatch('fetchAlerts').then(() => {
              commit('SET_ALERT_SEARCH_PERIOD', 'last_one_hour')
            })
          })
          .finally(() => {
            dispatch('transient/setDataLoaded', null, { root: true })
            Vue.$log.info('emit dataLoaded')
            serverBus.$emit('dataLoaded')
          })
      })
    })
    .catch(e => {
      Vue.$log.error(e)
      serverBus.$emit('dataLoaded')
      alert(e.message)
    })
  pinmeapi.syncUserData(user).catch(e => Vue.$log.error(e))
}

const actions = {
  refreshDevices({ commit, state }) {
    for (const d of state.devices) {
      d.poi = findNearestPOI(d.position)
      if (d.position) {
        d.position.fixDays = (new Date() - new Date(d.position.fixTime)) / (1000 * 60 * 60 * 24)
      }
      d.lastUpdate = new Date(d.lastUpdate).toISOString()
      commit('SET_DEVICE', d)
    }
  },
  async setDeviceLastIgnOff({ commit, state }, { device, lastStop }) {
    device.lastStop = lastStop
  },
  setGeofenceVisible({ commit }, geofence) {
    commit('SET_GEOFENCE_VISIBLE', geofence)
    commit('map/SET_GEOFENCE_VISIBLE', geofence, { root: true })
  },
  updateDevice({ commit }, device) {
    commit('SET_DEVICE', device)
  },
  async setGeofences({ commit }, geofences) {
    commit('SET_GEOFENCES', geofences)
  },
  setDevices({ commit }, devices) {
    commit('SET_DEVICES', devices)
  },
  addDriver({ commit }, driver) {
    commit('ADD_DRIVER', driver)
  },
  removeDriver({ commit }, device) {
    commit('REMOVE_DRIVER', device)
  },
  addUniqueId({ commit }, uniqueId) {
    commit('ADD_UNIQUEID', uniqueId)
  },
  removeUniqueId({ commit }, uniqueId) {
    commit('REMOVE_UNIQUEID', uniqueId)
  },
  setUniqueId({ commit }, uniqueIds) {
    commit('SET_DRIVERUNIQUEIDS', uniqueIds)
  },
  setMaintenances({ commit }, maintenances) {
    commit('SET_MAINTENANCES', maintenances)
  },
  addMaintenance({ commit }, maintenance) {
    commit('ADD_MAINTENANCE', maintenance)
  },
  removeMaintenance({ commit }, maintenance) {
    commit('REMOVE_MAINTENANCE', maintenance)
  },
  setMaintenance({ commit }, maintenance) {
    commit('SET_MAINTENANCE', maintenance)
  },
  addUser({ commit }, user) {
    commit('ADD_USER', user)
  },
  removeUser({ commit }, user) {
    commit('REMOVE_USER', user)
  },
  checkSession({ dispatch, commit }) {
    return new Promise((resolve) => {
      Vue.$log.info('user/checkSession')
      traccar.getSession().then((s) => {
        commit('SET_USER', s)
        resolve()
        dispatch('setUser')
      }).catch((e) => {
        Vue.$log.warn('no session, checking cognito', e)
        api.getJSessionId('')
          .then(() => {
            traccar.getSession().then((s) => {
              commit('SET_USER', s)
              resolve()
              dispatch('setUser')
            }).catch(e => resolve(e))
          })
          .catch(e => {
            Vue.$log.warn('no session, should go to login', e)
            dispatch('clearUser').then(() => resolve())
          })
      })
    })
  },
  setUser({ commit, state, dispatch }) {
    return initData(commit, state, dispatch)
      .catch(e => console.warn('initData', e))
      .finally(async() => {
        setLanguage(state.user.attributes.lang)
        await dispatch('setUserIdAuthHash')
        Sentry.setUser({ email: state.user.email })
        if (window.OneSignal && window.OneSignal.setEmail) {
          if (validEmail(state.user.email)) {
            await dispatch('setEmailAuthHash')
            window.OneSignal.setEmail(state.user.email.toLowerCase(), { emailAuthHash: state.user.attributes.emailAuthHash }).then()
              .catch(e => console.error(e))
          }
          window.OneSignal.setExternalUserId(state.user.id + '', state.user.attributes.userIdAuthHash).then()
            .catch(e => console.error(e))
        }
      })
  },
  getUser({ commit }) {
    return new Promise((resolve, reject) => {
      traccar.getUser().then(r => {
        const user = r.data
        resolve(commit('SET_USER', user))
      }).catch(e => reject(e))
    })
  },
  async login({ commit, dispatch }, userInfo) {
    const { username, password } = userInfo
    return Auth.signIn(username.trim().toLowerCase(), password)
      .then(async user => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          console.log('NEW_PASSWORD_REQUIRED')
          commit('SET_USER_CHANGEPASS', user)
          router.push('/changePassword')
          return
        }
        await api.getJSessionId()
        if (!window.location.href.includes('changePassword')) {
          dispatch('getUser')
            .then(() => dispatch('setUser'))
            .then(() => { router.push('/') })
            .catch(e => {
              commit('transient/SET_LOADING', false, { root: true })
              alert(e.message)
            })
        }
      })
      .catch(() => {
        return login({ username: username.trim(), password: password }).then(response => {
          const user = response.data
          commit('SET_USER', user)
          dispatch('setUser').then(() => { router.push('/') })
        }).catch(async e => {
          commit('CLEAR_USER')
          throw e
        })
      })
  },
  async logout({ commit }) {
    try {
      await logout()
      Vue.$log.info('logout one signal with 2 secs timeout', await Promise.race([
        window.OneSignal.logoutEmail(),
        new Promise(res => {
          setTimeout(res, 3000)
        })
      ]
      ))
    } catch (e) {
      // ignore
      Vue.$log.warn(e)
      if (e.errors) { Vue.$log.warn(e.errors) }
    } finally {
      commit('CLEAR_USER')
      vm.reset()
      try {
        Vue.$log.debug('deleting socket')
        delete window.socket
        Vue.$log.debug('Auth signout', await Auth.signOut())
      } catch (e) {
        Vue.$log.error(e)
      }
      window.location.href = '/'
    }
  },
  connect({ commit }) {
    commit('CONNECT')
  },
  setAlerts({ commit }, alerts) {
    commit('SET_ALERTS', alerts)
  },
  fetchAlerts({ commit, state }) {
    console.log('user/fetchAlerts')
    return traccar.alerts().then(async response => {
      const result = []
      response.data.sort((a, b) => (a.type > b.type) ? 1 : -1).forEach(a => {
        const alarm_data = {
          notification: a,
          devices: []
        }
        result.push(alarm_data)
      })
      // Add inactivity devices alert
      if (state.user.attributes.inactiveVehiclesEmail) {
        result.push(
          {
            notification: { id: 0, type: 'inactivity',
              always: true,
              notificators: 'mail',
              attributes: { otherEmails: state.user.attributes.inactiveVehiclesOtherEmails
              }
            },
            devices: []
          }
        )
      }
      commit('SET_ALERTS', result)
      if (state.devices) {
        if (window.location.href.includes('/settings/alerts') || state.loadNotificationByDevice) {
          for (const d of state.devices) {
            await traccar.alertsByDevice(d.id)
              .then(({ data }) => {
                data.forEach(a => {
                  const alert = state.alerts.find(a_data => a_data && a_data.notification.id === a.id)
                  if (a.type === 'geofenceExit' || a.type === 'geofenceEnter') {
                    traccar.geofencesByDevice(d.id).then(geofences => {
                      d.geofences = geofences
                      alert.devices.push(d)
                    })
                  } else {
                    alert.devices.push(d)
                  }
                })
              })
              .catch(e => console.error(e, d, 'moving on...'))
          }
          state.alerts.forEach(a => {
            if (a.notification.always === true) {
              state.devices.forEach(d => {
                if (a.notification.type === 'geofenceExit' || a.notification.type === 'geofenceEnter') {
                  traccar.geofencesByDevice(d.id).then(geofences => {
                    d.geofences = geofences
                    a.devices.push(d)
                  })
                } else {
                  a.devices.push(d)
                }
              })
            }
          })
        } else {
          Vue.$log.warn('skip SET_ALERTS devices:', state.devices.length)
        }
      } else {
        Vue.$log.error('devices is null', state.devices)
      }
    })
  },
  async processGroups({ state, dispatch, commit }) {
    await new Promise((resolve, reject) => {
      const groupIds = state.groups.map(g => g.id)
      traccar.geofencesByGroup(groupIds, function(results) {
        if (state.geofences.length < 30000) {
          results.forEach(result => {
            const g = state.groups.find(g => g.id === groupIds[results.indexOf(result)])
            result.forEach(g_data => {
              let geofence = state.geofences.find(g => g.id === g_data.id)
              if (!geofence) {
                state.geofences.push(g_data)
                geofence = g_data
              }
              geofence.groupName = g.name
              if (g.attributes.groupIcon) {
                geofence.attributes.icon = g.attributes.groupIcon
              }
              if (g.attributes.groupColor && geofence.area.startsWith('CIRCLE')) {
                geofence.attributes.color = g.attributes.groupColor
              }
              if (g.attributes.groupImg) {
                geofence.attributes.icon = 'groupImage'
                geofence.attributes.color = g.id + ''
                geofence.attributes.img = g.attributes.groupImg
              }
            })
            g.geofences =
              {
                geofences: result.filter(g => g.area.startsWith('POLYGON')).map(g => g.id),
                pois: result.filter(g => g.area.startsWith('CIRCLE')).map(g => g.id),
                linegeofences: result.filter(g => g.area.startsWith('LINESTRING')).map(g => g.id)
              }
          })
        }
        lnglat.processGeofences(state.geofences)
          .then(() => dispatch('setGeofences', state.geofences)
            .catch(e => console.error(e))
            .then(() => resolve()).catch(e => reject(e)))
      }, (e) => {
        Vue.$log.error(e)
        reject(e)
      })
    })

    await new Promise((resolve, reject) => {
      state.groups.forEach(g => {
        g.users = []
      })
      traccar.groupsByUsers(state.users.map(u => u.id), function(results) {
        results.forEach(result => {
          result.forEach(d_data => {
            const user = state.users[results.indexOf(result)]
            const group = state.groups.find(g => g.id === d_data.id)
            if (group && user) {
              group.users.push(user)
            }
          })
        })
        resolve()
      }, (e) => {
        Vue.$log.error(e)
        reject(e)
      })
    }).then(r => {
      Vue.$log.debug(r)
    })

    if (window.location.href.includes('/settings')) {
      await new Promise((resolve, reject) => {
        traccar.driversByGroup(state.groups.map(g => g.id), function(results) {
          results.forEach(result => {
            result.forEach(d_data => {
              if (!state.drivers.find(d => d.id === d_data.id)) {
                state.drivers.push(d_data)
              }
            })
            commit('SET_GROUP_DRIVERS', { index: results.indexOf(result), drivers: result.map(g => g.id) })
          })
          resolve()
        }, (e) => {
          Vue.$log.error(e)
          reject(e)
        })
      }).then(r => {
        Vue.$log.debug(r)
      })
    }
  },
  clearUser({ commit }) {
    return new Promise((resolve) => {
      commit('CLEAR_USER')
      resolve()
    })
  },
  async processMaitenances({ state, commit }) {
    if (state.maintenances.length) {
      const deviceIds = state.devices.map(d => d.id)
      let results = await traccar.maintenancesByDevice(deviceIds)
      results.forEach(result => {
        const device = state.devices.find(d => d.id === deviceIds[results.indexOf(result)])
        result.forEach(data => {
          const maintenance = state.maintenances.find(m => m.id === data.id)
          if (maintenance && maintenance.devices) {
            maintenance.devices.push(device.id)
          } else {
            maintenance.devices = [device.id]
          }
          commit('SET_MAINTENANCE', maintenance)
        })
      })

      const groupIds = state.groups.map(g => g.id)
      results = await traccar.maintenancesByGroup(groupIds)
      results.forEach(result => {
        const group = state.groups.find(g => g.id === groupIds[results.indexOf(result)])
        result.forEach(data => {
          const maintenance = state.maintenances.find(m => m.id === data.id)
          if (maintenance && maintenance.groups) {
            maintenance.groups.push(group.id)
          } else {
            maintenance.groups = [group.id]
          }
        })
      })
    }
  },
  async setEmailAuthHash({ state, commit }) {
    try {
      const r = await backend.getEmailAuthHash(state.user.email.toLowerCase(), window.location.hostname)
      commit('SET_EMAIL_AUTH_HASH', r.data)
    } catch (e) {
      Vue.$log.error(e)
    }
  },
  async setUserIdAuthHash({ state, commit }) {
    try {
      const r = await backend.getEmailAuthHash(state.user.id, window.location.hostname)
      commit('SET_USERID_AUTH_HASH', r.data)
    } catch (e) {
      Vue.$log.error(e)
    }
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
