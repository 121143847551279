import length from '@turf/length'
import distance from '@turf/distance'
import mapboxgl from 'mapbox-gl'
import axios from 'axios'
import bbox from '@turf/bbox'
import * as helpers from '@turf/helpers'
import { vm } from '@/main'
import styles from '../styles/element-variables.scss'
import store from '../store'
import { positionsSource, vehIconsUrl } from './consts'
import * as angles from 'angles'
import Vue from 'vue'
import * as utils from '@/utils/utils'
import EventPopUp from '../views/map/EventPopUp'
import i18n from '../lang'

const imagesLoading = {}
export function addVehicleImage(_imageName) {
  if (!imagesLoading[_imageName]) {
    imagesLoading[_imageName] = true
    for (let i = 0; i < 25; i++) {
      const imageName = _imageName.slice(0, -2) + i.toString().padStart(2, '0')
      vm.$static.map.loadImage(vehIconsUrl + `/${imageName}.png`, function(error, image) {
        if (error) {
          imagesLoading[imageName] = false
          console.error(error)
        }
        if (!vm.$static.map.hasImage(imageName)) {
          vm.$static.map.addImage(imageName, image)
        }
        imagesLoading[imageName] = false
      })
    }
  }
}

const gray = ['==', ['get', 'color'], 'gray']
const green = ['==', ['get', 'color'], 'green']
const yellow = ['==', ['get', 'color'], 'yellow']
const red = ['==', ['get', 'color'], 'red']

export function centerVehicle(feature) {
  vm.$static.map.flyTo({
    essential: true,
    center: feature.geometry.coordinates,
    zoom: 16,
    bearing: feature.properties.course,
    pitch: 60
  })
}

let markersOnScreen = {}
let currentState = null

const colors = [styles.info, styles.success, styles.warning, styles.danger]

const { body } = document
const WIDTH = 768 // refer to Bootstrap's responsive design

export const popUps = []
export const eventPopUps = []

export function __isMobile() {
  const rect = body.getBoundingClientRect()
  return rect.width - 1 < WIDTH && !location.href.includes('iosdashboard')
}
export function getGeoJSON(coords) {
  return helpers.featureCollection([helpers.feature(coords)])
}
export function getGeoJSONFeatures(coordsArray) {
  return helpers.featureCollection(coordsArray.map(coords => helpers.feature(coords)))
}
export function getGeoJSONFeaturesColletion(features) {
  return helpers.featureCollection(features)
}
export function findFeatureByDeviceId(deviceId) {
  return vm.$static.positionsSource.features.find(e => e.properties.deviceId === deviceId)
}
export function getArea(area) {
  if (area.features.length) {
    if (area.features[0].geometry.type.toUpperCase() === 'POINT') {
      return 'CIRCLE (' + area.features[0].geometry.coordinates[1] + ' ' + area.features[0].geometry.coordinates[0] + ', 100)'
    } else if (area.features[0].geometry.type.toUpperCase() === 'LINESTRING') {
      return 'LINESTRING (' + area.features[0].geometry.coordinates.map(c => c[1] + ' ' + c[0]).join(',') + ')'
    } else {
      return area.features[0].geometry.type.toUpperCase() + '((' + area.features[0].geometry.coordinates[0].map(e => e[1] + ' ' + e[0]).join(',') + '))'
    }
  }
  return undefined
}
export function getBounds(coordinates) {
  const line = helpers.lineString(coordinates)
  return bbox(line)
}
export function arrayDistance(coordinates) {
  const lineString = {
    type: 'LineString',
    coordinates: coordinates
  }
  return lineDistance(lineString)
}
export function coordsDistance(lon1, lat1, lon2, lat2) {
  const from = helpers.point([lon1, lat1])
  const to = helpers.point([lon2, lat2])
  const options = { units: 'kilometers' }

  return (distance(from, to, options) * 1000)
}

export function lineDistance(route) {
  return length(route, { units: 'kilometers' })
}
export function isMobile() {
  return __isMobile()
}

export function isSafari() {
  return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') === -1 &&
    navigator.userAgent.indexOf('FxiOS') === -1
}

export class MapboxCustomControl {
  constructor(id) {
    this.id = id
  }
  onAdd(map) {
    this.map = map
    this.container = document.createElement('div')
    this.container.className = 'mapboxgl-ctrl'
    this.container.id = this.id
    return this.container
  }
  onRemove() {
    // this.container.parentNode.removeChild(this.container);
    this.map = undefined
  }
}
export function matchRoute(coordinates, radius, timestamps, onSuccess, onError) {
  const query = 'https://api.mapbox.com/matching/v5/mapbox/driving/' +
    coordinates.join(';') + '?geometries=geojson&radiuses=' +
    radius.join(';') + '&timestamps=' +
    timestamps.join(';') + '&access_token=' + mapboxgl.accessToken
  axios.get(query)
    .then(onSuccess)
    .catch(onError)
}

function createDonutChart(props) {
  const offsets = []
  const counts = [
    currentState === null || currentState === 'Disconnected' ? props.gray : 0,
    currentState === null || currentState === 'Moving' ? props.green : 0,
    currentState === null || currentState === 'Idle' ? props.yellow : 0,
    currentState === null || currentState === 'Stopped' ? props.red : 0]

  let total = 0
  for (let i = 0; i < counts.length; i++) {
    offsets.push(total)
    total += counts[i]
  }

  if (total < 2) { return null }

  const fontSize = total >= 30 ? 20 : total >= 15 ? 16 : total >= 10 ? 15 : 14
  const r = total >= 30 ? 22 : total >= 24 ? 20 : total >= 10 ? 18 : 16
  const r0 = Math.round(r * 0.75)
  const w = r * 2

  let html = `<svg width="${w}" height="${w}" viewBox="0 0 ${w} ${w}" text-anchor="middle" style="font: ${fontSize}px sans-serif">`

  for (let i = 0; i < counts.length; i++) {
    html += donutSegment(offsets[i] / total, (offsets[i] + counts[i]) / total, r, r0, colors[i])
  }
  html += '<circle cx="' + r + '" cy="' + r + '" r="' + r0 +
    '" fill="#f5f5f5" /><text dominant-baseline="central" transform="translate(' +
    r + ', ' + r + ')">' + total.toLocaleString() + '</text></svg>'

  const el = document.createElement('div')

  el.innerHTML = html
  return el
}
function donutSegment(start, end, r, r0, color) {
  if (end - start === 1) end -= 0.00001
  const a0 = 2 * Math.PI * (start - 0.25)
  const a1 = 2 * Math.PI * (end - 0.25)
  const x0 = Math.cos(a0)
  const y0 = Math.sin(a0)
  const x1 = Math.cos(a1)
  const y1 = Math.sin(a1)
  const largeArc = end - start > 0.5 ? 1 : 0

  return ['<path d="M', r + r0 * x0, r + r0 * y0, 'L', r + r * x0, r + r * y0,
    'A', r, r, 0, largeArc, 1, r + r * x1, r + r * y1,
    'L', r + r0 * x1, r + r0 * y1, 'A',
    r0, r0, 0, largeArc, 0, r + r0 * x0, r + r0 * y0,
    '" fill="' + color + '" />'].join(' ')
}

export function updateBearing(feature) {
  if (feature) {
    feature.properties.bearing = vm.$static.map.getBearing()
    feature.properties.courseMinusBearing = angles.normalize(feature.properties.course - feature.properties.bearing)
  }
}

const VD = Vue.extend(VehicleTable)
let lastMarker
const aliveClusters = []
export function updateDonuts() {
  const newMarkers = {}
  try {
    const features = vm.$static.map.querySourceFeatures(positionsSource, { filter: ['boolean', !store.getters.historyMode] })
    // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
    // and add it to the map if it's not there already
    for (let i = 0; i < features.length; i++) {
      const coords = features[i].geometry.coordinates
      const props = features[i].properties
      if (!props.cluster) {
        const feature = findFeatureByDeviceId(props.deviceId)
        updateBearing(feature)
        continue
      }
      const id = props.cluster_id
      let marker = vm.$static.markers[id]
      if (!marker) {
        const el = createDonutChart(props)
        if (el === null) continue
        marker = vm.$static.markers[id] = new mapboxgl.Marker({ element: el }).setLngLat(coords)
        vm.$static.map.getSource(positionsSource).getClusterLeaves(id, 100000, 0, (error, features) => {
          if (!error) {
            marker.setPopup(new mapboxgl.Popup().setHTML(`<div style="padding: 15px 0 10px 0"><div id="cluster_${id}"></div></div>`))
            el.addEventListener('click', () => {
              if (store.getters.dataContainerTabActive !== '' && store.getters.dataContainerTabActive !== '0') {
                return
              }
              // close the other
              if (lastMarker && lastMarker !== marker) {
                if (lastMarker.getPopup().isOpen()) { lastMarker.togglePopup() }
              }
              lastMarker = marker
              // open the new one
              if (!marker.getPopup().isOpen()) {
                marker.togglePopup()
                marker.getPopup().getElement().addEventListener('mouseleave', () => marker.togglePopup())
                if (aliveClusters.indexOf(id) < 0) {
                  // only one vue component needed
                  new VD({ store: vm.$store, i18n: vm.$i18n, data: { filterDevices: features.map(f => f.properties.deviceId) }}).$mount(`#cluster_${id}`)
                  aliveClusters.push(id)
                }
              }
            })
          }
        })
      }
      newMarkers[id] = marker
      if (!markersOnScreen[id]) {
        marker.addTo(vm.$static.map)
      }
    }
    // for every marker we've added previously, remove those that are no longer visible
    for (const id in markersOnScreen) {
      // noinspection JSUnfilteredForInLoop
      if (Object.prototype.hasOwnProperty.call(newMarkers, id)) {
        continue
      }
      if (Object.prototype.hasOwnProperty.call(markersOnScreen, id)) {
        const remove = markersOnScreen[id]
        remove.remove()
      }
    }
    markersOnScreen = newMarkers
  } catch (e) {
    console.warn('updateDonuts', e)
  }
}

export function contains(lngLatBounds, position, padding = 0) {
  return (
    (lngLatBounds.getWest() + padding < position.longitude && position.longitude < lngLatBounds.getEast() - padding) &&
    (lngLatBounds.getSouth() + padding < position.latitude && position.latitude < lngLatBounds.getNorth() - padding)
  )
}

export function refreshGeofences() {
  if (vm.$static.map && vm.$static.map.getSource('geofences')) {
    vm.$static.map.getSource('geofences').setData(vm.$static.geofencesSource)
  }
}

export function changeVehicleLayerFilter(state) {
  currentState = state
  if (state === null) {
    vm.$static.map.setFilter('vehiclesLayer', ['!=', ['get', 'cluster'], true])
    vm.$static.map.setFilter('vehicleLabels', ['!=', ['get', 'cluster'], true])
  }
  if (state === 'Moving') {
    vm.$static.map.setFilter('vehiclesLayer', ['all', ['!=', ['get', 'cluster'], true], green])
    vm.$static.map.setFilter('vehicleLabels', ['all', ['!=', ['get', 'cluster'], true], green])
  }
  if (state === 'Idle') {
    vm.$static.map.setFilter('vehiclesLayer', ['all', ['!=', ['get', 'cluster'], true], yellow])
    vm.$static.map.setFilter('vehicleLabels', ['all', ['!=', ['get', 'cluster'], true], yellow])
  }
  if (state === 'Disconnected') {
    vm.$static.map.setFilter('vehiclesLayer', ['all', ['!=', ['get', 'cluster'], true], gray])
    vm.$static.map.setFilter('vehicleLabels', ['all', ['!=', ['get', 'cluster'], true], gray])
  }
  if (state === 'Stopped') {
    vm.$static.map.setFilter('vehiclesLayer', ['all', ['!=', ['get', 'cluster'], true], red])
    vm.$static.map.setFilter('vehicleLabels', ['all', ['!=', ['get', 'cluster'], true], red])
  }

  // To update cluster markers
  vm.$static.markers = []
  for (const id in markersOnScreen) {
    // noinspection JSUnfilteredForInLoop
    const remove = markersOnScreen[id]
    remove.remove()
  }
  updateDonuts()
}
export function fitBounds(devices) {
  const features = vm.$static.positionsSource.features.filter(f => devices.findIndex(d => d.id === f.properties.deviceId) >= 0)
  if (features.length > 1) {
    const coords = features.map(f => f.geometry.coordinates)
    const box = bbox(helpers.lineString(coords))
    const bounds = [[box[0], box[1]], [box[2], box[3]]]
    vm.$static.map.fitBounds(bounds, { padding: 30 })
    updateDonuts()
  }
}

export function showPopup(feature, device) {
  const coordinates = feature.geometry.coordinates.slice()
  popUps.forEach(p => p.remove())

  popUps[device.id] = new mapboxgl.Popup({ class: 'card2', offset: 25 })
    .setLngLat(coordinates)
    .setHTML('<div id="vue-vehicle-popup"></div>')
    .addTo(vm.$static.map)
    .on('close', () => {
      Vue.$log.debug('popup closed', device.name)
      popUps[device.id].closed = true
    })
  const position = { ...feature.properties }
  position.attributes = { ...feature.properties }
  const VD = Vue.extend(VehicleDetail)
  this.lastPopup = new VD({
    i18n: i18n,
    data: {
      device: device,
      position,
      feature,
      routePoint: true
    },
    store: store
  })
  this.lastPopup.$mount('#vue-vehicle-popup')
}

export function hidePopup(device) {
  if (popUps[device.id]) {
    popUps[device.id].remove()
  }
}

export function showEventPopup(feature, newPopup, eventPopupOnClose) {
  eventPopUps.push(newPopup
    .setLngLat(feature.geometry.coordinates.slice())
    .setHTML('<div id="vue-event-popup"></div>')
    .addTo(vm.$static.map)
    .on('close', eventPopupOnClose))
  const PP = Vue.extend(EventPopUp)
  const vuePopup = new PP({
    i18n: i18n,
    store,
    data: {
      properties: feature.properties,
      lngLat: feature.geometry.coordinates
    }
  })
  vuePopup.$mount('#vue-event-popup')
}

export function hideEventPopup() {
  if (eventPopUps.length > 0) {
    eventPopUps.forEach(e => e.remove())
    eventPopUps.splice(0, eventPopUps.length)
  }
}

export function updateDevice(position, feature, device) {
  device.lastUpdate = position.fixTime
  const adc1CacheValues = device.position && device.position.adc1CacheValues ? device.position.adc1CacheValues : []
  utils.calculateFuelLevel(adc1CacheValues, position, device.position, device)
  const driver = findDriver(position, device)
  if (position.attributes.ignition) { device.driver = driver } else if (!device.driver && device.attributes.lastDriverUniqueId) {
    device.driver = store.getters.drivers.find(d => (d.uniqueId).trim().localeCompare((device.attributes.lastDriverUniqueId).trim(), 'en', { sensitivity: 'base' }) === 0) ||
      { name: device.attributes.lastDriverUniqueId }
  }
  let immobilized =
    (device.attributes.deviceType !== 36 && position.attributes.output) ||
    position.attributes.do1 ||
    position.attributes.out1 ||
    position.attributes.out2 ||
    position.attributes.isImmobilizationOn ||
    position.attributes.blocked ||
    (position.attributes.result && position.attributes.result.startsWith('   Cut off the fuel supply Success! Speed:')) ||
    position.attributes.result === '   Already in the state of fuel supply cut off, the command is not running!'

  if (position.attributes.output === '00') {
    immobilized = false
  }

  if (position.attributes.result && position.attributes.result.startsWith('+ACK:GTOUT')) {
    Vue.$log.info('changing immobilized', position.attributes.result, !device.attributes.immobilized)
    immobilized = !device.attributes.immobilized
    position.attributes.output = immobilized
  }

  if (immobilized !== device.attributes.immobilized) {
    device.attributes.commandPending = false
  }
  if (device.attributes.deviceType === 7 &&
    !immobilized && position.attributes.blocked === undefined &&
    position.attributes.result !== '   Restore fuel supply success!') {
    Vue.$log.info('ignoring immobilized value', position.attributes.result, device.name)
  } else if (position.attributes.result && position.attributes.result.startsWith('+ACK:GTFRI')) {
    Vue.$log.info('ignoring immobilized value', position.attributes.result, device.name)
  } else {
    device.attributes.immobilized = immobilized
  }
  if (device.position && device.position.attributes.ignition && !position.attributes.ignition) {
    device.lastStop = position.fixTime
  }
  device.position = position
}

export function findNearestPOI(position, forceSearch = false) {
  if (!position) { return null }
  const pois = store.getters.geofences.filter(g => g && g.area && g.area.startsWith('CIRCLE'))
  if (pois.length === 0 || (!forceSearch && pois.length > 1000)) {
    return null
  }
  const a = pois.map(p => {
    if (p.area) {
      const str = p.area.substring('CIRCLE ('.length, p.area.indexOf(','))
      const coord = str.trim().split(' ')
      return {
        id: p.id,
        distance: Math.round(coordsDistance(parseFloat(coord[1]), parseFloat(coord[0]), position.longitude, position.latitude)),
        maxDistance: getGeofenceDistance(p)
      }
    }
    return {
      id: p.id,
      distance: Number.MAX_SAFE_INTEGER,
      maxDistance: 0
    }
  }).filter(a => a.distance < a.maxDistance).sort((a, b) => (a.distance > b.distance) ? 1 : -1)
  if (a.length > 0) {
    return a[0].id
  }
}

export function getGeofenceType(geofence) {
  if (geofence.area.startsWith('POLYGON')) { return 'geofence' }
  if (geofence.area.startsWith('LINE')) { return 'line' }
  return 'poi'
}
export function getGeofenceDistance(geofence) {
  return parseInt(geofence.area.slice(0, geofence.area.length - 1).split(',')[1].trim())
}

function findDriver(position, device) {
  if (!position.attributes.driverUniqueId || position.attributes.driverUniqueId === '0') {
    return undefined
  }

  const driver = store.getters.drivers.find(d => (d.uniqueId).trim() === (position.attributes.driverUniqueId).trim())
  if (position.fixDays > 5 || position.outdated) {
    return undefined
  }

  if (driver) {
    if (!driver.attributes.deviceId || driver.attributes.deviceId !== device.id) {
      vm.$store.state.user.drivers.splice(vm.$store.state.user.drivers.indexOf(driver), 1)
      driver.attributes.deviceId = device.id
      vm.$store.state.user.drivers.push(driver)
    }
    return { id: driver.id, name: driver.name }
  }

  return { name: position.attributes.driverUniqueId }
}

import geofencesLayer from '../views/map/mapbox/layers/GeofencesLayer'
import { hexToRgb } from './images'
import VehicleDetail from '@/views/map/VehicleDetail'
import VehicleTable from '@/views/map/VehicleTable.vue'

export async function changeImage(icon, uniqueColor, imageName) {
  const hex = imageName.slice(imageName.length - 6, imageName.length)
  const canvas = document.createElement('canvas')
  canvas.width = 27
  canvas.height = 27
  const ctx = canvas.getContext('2d')
  const image = document.createElement('img')
  const imgSrc = await axios.get('./img/icons/pois/' + icon + '-blue.svg', { responseType: 'arrayBuffer' })
    .then(r => Buffer.from(r.data.replace(/#3232b4/ig, `#${hex}`), 'binary').toString('base64'))
  await new Promise((resolve, reject) => {
    image.onload = resolve
    image.onerror = reject
    image.src = 'data:image/svg+xml;base64,' + imgSrc
  })
  ctx.drawImage(image, 0, 0)
  const imgd = ctx.getImageData(0, 0, 27, 27)
  const pix = imgd.data
  return { canvas, ctx, imgd, pix }
}

export async function generateImage(icon, uniqueColor, imageName) {
  if (!changedImages[imageName]) {
    Vue.$log.info('changing', imageName)
    const { canvas, ctx, imgd, pix } = await changeImage(icon, uniqueColor, imageName)
    if (vm.$static.map && !vm.$static.map.hasImage(imageName)) {
      vm.$static.map.addImage(imageName, { width: 27, height: 27, data: pix })
    } else if (vm.$static.map) {
      return
    }

    ctx.putImageData(imgd, 0, 0)
    await new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        try {
          changedImages[imageName] = URL.createObjectURL(blob)
        } catch (e) {
          console.error(e)
          reject(e)
        }
      })
      resolve()
    })
  }
}

let changedImages = {}

export function resetImages() {
  changedImages = {}
}
export async function addImageToMap(icon, uniqueColor, imageName) {
  if (!vm.$static.map || !vm.$static.map.hasImage(imageName)) {
    await generateImage(icon, uniqueColor, imageName)
  }
}

function fixCors(image) {
  return image.replace('https://reports-backend-responsesbucket-1pz6dzs2zfuh9.s3.us-east-1.amazonaws.com/', '/poi-images/')
}

const loadingImages = []

export async function processGeofence(item) {
  try {
    if (item.attributes.icon || item.attributes.color) {
      const imageName = (item.attributes.icon || 'marker') + (item.attributes.color ? item.attributes.color.replace('#', '') : '3232b4')
      if (item.attributes.img && !item.attributes.img.startsWith('blob') && !item.attributes.img.endsWith('.svg')) {
        const map = vm.$static.map
        if (map && !map.hasImage(imageName) && loadingImages.indexOf(imageName) < 0) {
          loadingImages.push(imageName)
          map.loadImage(fixCors(item.attributes.img), function(error, image) {
            if (error) {
              console.error('error map.loadImage', item.attributes.img, error)
            } else {
              if (!map.hasImage(imageName)) {
                map.addImage(imageName, image)
              }
            }
          })
        }
      } else {
        await addImageToMap(
          imageName.slice(0, imageName.length - 6),
          hexToRgb(imageName.slice(imageName.length - 6, imageName.length)),
          imageName)
        item.attributes.img = changedImages[imageName]
      }
    } else {
      item.attributes.img = './img/icons/pois/marker-blue.svg'
    }
    item.visible = store.state.map.hiddenGeofences.indexOf(item.id) === -1
  } catch (e) {
    Vue.$log.error(e, item)
  }
}

export async function processGeofences(geofences) {
  for (const item of geofences) {
    await processGeofence(item)
  }
}

export function getGeofencesGeoJson(geofences) {
  const result = []
  for (const item of geofences) {
    if (item) {
      const geoJson = geofencesLayer.getFeatureGeojson(item)
      if (geoJson && geoJson.properties.icon && geofences.length < 30000) { result.push(geofencesLayer.getFeatureCircleGeojson(item)) }
      if (geoJson) {
        result.push(geoJson)
      }
    }
  }
  return result
}
